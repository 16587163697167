import {
  Box,
  Button,
  createTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
} from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useRecoilState, useRecoilValue } from "recoil";
import { ffdIyziTeamDimemsionState } from "../../../../atoms/operation";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import Errors from "../../../../utils/Errors";

export default function OperationDimensionDomesticCheck({ items, i, isBeevision, id, manuelScan, item, setManuelScan }) {
  const [dimensions, setDimensions] = useRecoilState(ffdIyziTeamDimemsionState);
  const [disable, setDisable] = useState(false)

  console.log(item?.[0]?.domestic_supplier || "No supplier available");

  const [totalCw, setTotalCw] = useState();

  const [loading, setLoading] = useState(true);

  const isDomesticChangesPage = window.location.href.includes("domestic-info-changes-screen");

  const roundWeight = (weight) => {
    if (weight <= 0.01) {
      return 0.01;
    } else if (weight < 10) {
      const integerPart = Math.floor(weight);
      const decimalPart = weight - integerPart;
      if (decimalPart > 0 && decimalPart <= 0.5) {
        return integerPart + 0.5;
      } else {
        return Math.ceil(weight * 2) / 2;
      }
    } else {
      return Math.ceil(weight);
    }
  };

  const handleInputChange = useCallback(
    (e, i) => {
      const { name, value } = e.target;
  
      // Eğer 'KAYDET' yazılmışsa ilgili işlemi yap
      if (value && value.includes("KAYDET")) {
        setManuelScan("KAYDET");
      }

      if (value && value.includes("DÜZENLE")) {
        setManuelScan("DÜZENLE");
        return; // Geri kalan işlemleri yapmamak için return
      }
  
      // Eğer 'YENİ' yazılmışsa sayfayı yenile
      if (value && value.includes("YENİ")) {
        window.location.reload();
      }
      
  
      // Diğer değerler için işlem
      setDimensions((prevDimensions) => {
        const updatedDimensions = [...prevDimensions];
        let fvalue = value.replace(/,/g, ".");
  
        if (name === "qty") {
          updatedDimensions[i] = {
            ...updatedDimensions[i],
            qty: fvalue.replace(/[^0-9]/g, ""),
          };
        } else {
          const value = fvalue.replace(/[^0-9.]|(?<=\..*)\.|^0{2,}(?=[\.])/g, "");
          const regex = /^\d+(\.\d{0,2})?$/;
  
          if (value.startsWith(".") || (!regex.test(value) && value !== "")) {
            return prevDimensions;
          } else {
            if (name === "gw") {
              let newValue = value.replace(/^0{2,}/, "0");
              if (Number(fvalue) > 300) {
                newValue = "300";
              }
              updatedDimensions[i] = {
                ...updatedDimensions[i],
                gw: newValue,
              };
            } else {
              updatedDimensions[i] = {
                ...updatedDimensions[i],
                [name]: value,
              };
            }
          }
        }
  
        const calculatedCw = updatedDimensions?.map((dimension) =>
          ((Number(dimension.width) *
            Number(dimension.length) *
            Number(dimension.height)) / 5000) *
            Number(dimension.qty) >
          Number(dimension.gw) * Number(dimension.qty)
            ? ((Number(dimension.width) *
                Number(dimension.length) *
                Number(dimension.height)) / 5000) *
              Number(dimension.qty)
            : Number(dimension.gw) * Number(dimension.qty)
        );
  
        setTotalCw(
          roundWeight(calculatedCw?.reduce((acc, item) => acc + item, 0))
        );
        return updatedDimensions;
      });
    },
    []
  );
  
  useEffect(() => {
    if (manuelScan === "KAYDET") {
      handleUpdateDomesticInfo();
      return; // Geri kalan işlemleri yapmamak için return
    }

    if (manuelScan === "YENİ") {
      window.location.reload();
      setManuelScan("");
      return; // Geri kalan işlemleri yapmamak için return
    }

    if (manuelScan === "DÜZENLE") {
      setDisable(false);
      setManuelScan("");
      return; // Geri kalan işlemleri yapmamak için return
    }
  }, [manuelScan]);

  useEffect(() => {
    if (i > 0) {
      // Request sonucunda gelen veri "responseData" olarak adlandırılmıştır.
      const filteredItems = items?.filter((item) => item);
      if (filteredItems.length > 0) {
        const transformedData = filteredItems?.map((item, index) => ({
          width: item.width || "",
          length: item.length || "",
          height: item.height || "",
          gw: item.gw || "",
          qty: item.qty || "",
          id: item.id !== 0 ? item.id : 0, // Bu kısmı, unique bir id almak için item.id olarak da ayarlayabilirsiniz.
        }));

        // Dönüştürülmüş veriyi "dimensions" state'ine ayarlama
        setDimensions(transformedData);
        const calculatedCw = transformedData?.map((dimension) =>
          ((Number(dimension.width) *
            Number(dimension.length) *
            Number(dimension.height)) /
            5000) *
            Number(dimension.qty) >
            Number(dimension.gw) * Number(dimension.qty)
            ? ((Number(dimension.width) *
              Number(dimension.length) *
              Number(dimension.height)) /
              5000) *
            Number(dimension.qty)
            : Number(dimension.gw) * Number(dimension.qty)
        );

        setTotalCw(calculatedCw?.reduce((acc, item) => acc + item, 0));
      } else {
        const transformedData = items?.map((item, index) => ({
          //width: item.width,
          width: "",
          // length: item.length,
          length: "",
          // height: item.height,
          height: "",
          // gw: item.gw,
          gw: "",
          // qty: item.qty,
          qty: "",
          id: 0, // Bu kısmı, unique bir id almak için item.id olarak da ayarlayabilirsiniz.
        }));

        // Dönüştürülmüş veriyi "dimensions" state'ine ayarlama
        setDimensions(transformedData);
        const calculatedCw = transformedData?.map((dimension) =>
          ((Number(dimension.width) *
            Number(dimension.length) *
            Number(dimension.height)) /
            5000) *
            Number(dimension.qty) >
            Number(dimension.gw) * Number(dimension.qty)
            ? ((Number(dimension.width) *
              Number(dimension.length) *
              Number(dimension.height)) /
              5000) *
            Number(dimension.qty)
            : Number(dimension.gw) * Number(dimension.qty)
        );

        setTotalCw(calculatedCw?.reduce((acc, item) => acc + item, 0));
      }

      // Gelen veriyi "dimensions" state formatına dönüştüren bir fonksiyon
    }
  }, [i, items, setDimensions]);

  const handleUpdateDomesticInfo = () => {
    const requestBody = {
      domestic_supplier_name: item[0].domestic_supplier,
      domestic_awb: item[0].domestic_awb,
      domestic_price: 0,
      domestic_cost: 0,
      length: dimensions[0].length,
      width: dimensions[0].width,
      height: dimensions[0].height,
      gw: dimensions[0].gw,
      gross: dimensions[0].gw,
      qty: 1
    };

    setLoading(true); // İşlem başladığında loading state aktif ediliyor

    axios
      .post(`parcel/operation/add-domestic-parcel/${id}`, requestBody)
      .then((response) => {
        // API çağrısı başarılı olursa yapılacak işlemler
        toast.success(response.data.message);
        setDisable(true);
      })
      .catch((error) => {
        // Hata olursa yapılacak işlemler
        Errors(error);
      })
      .finally(() => {
        // API çağrısı tamamlandığında loading state devre dışı bırakılıyor
        setLoading(false);
        setManuelScan("");
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "var(--gap)",
        opacity: disable ? "0.5" : "1",
      }}
    >
      {i > 0 &&
        dimensions?.map((dim, index) => (
          <form
            onSubmit={(e) => e.preventDefault()} // Formun submitini engelliyoruz
            className="operation-cargo-dom-resp-weight-iyziteam-dimension"
            key={index}
            style={{
              display: isBeevision === true ? "flex" : "",
              gap: isBeevision === true ? "20px" : "",
              paddingTop: isBeevision === true ? "20px" : "",
            }}
          >
            <div className="operation-cargo-dom-resp-weight-iyziteam-item">
              <div className="little-title">Genişlik</div>
              <TextField
                size="medium"
                name="width"
                value={dim.width}
                disabled={disable}
                required
                onChange={(e) => handleInputChange(e, index)}
                sx={{
                  backgroundColor: "#e9ecef",
                  width: "70px",
                  height: "20px",
                  fontSize: "10px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0",
                  "& fieldset": { border: "none" },
                }}
              />
            </div>
            <div className="operation-cargo-dom-resp-weight-iyziteam-item">
              <div className="little-title">Uzunluk</div>
              <TextField
                size="medium"
                name="length"
                required
                value={dim.length}
                disabled={disable}
                onChange={(e) => handleInputChange(e, index)}
                sx={{
                  backgroundColor: "#e9ecef",
                  width: "70px",
                  height: "20px",
                  fontSize: "10px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0",
                  "& fieldset": { border: "none" },
                }}
              />
            </div>
            <div className="operation-cargo-dom-resp-weight-iyziteam-item">
              <div className="little-title">Yükseklik</div>
              <TextField
                size="medium"
                name="height"
                value={dim.height}
                disabled={disable}
                required
                onChange={(e) => handleInputChange(e, index)}
                sx={{
                  backgroundColor: "#e9ecef",
                  width: "70px",
                  height: "20px",
                  fontSize: "10px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0",
                  "& fieldset": { border: "none" },
                }}
              />
            </div>
            {/* <div className="operation-cargo-dom-resp-weight-iyziteam-item">
              <div className="little-title">Kap</div>
              <TextField
                size="medium"
                value={dim.qty}
                required
                disabled={disable}
                onChange={(e) => handleInputChange(e, index)}
                name="qty"
                sx={{
                  backgroundColor: "#e9ecef",
                  width: "70px",
                  height: "20px",
                  fontSize: "10px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0",
                  "& fieldset": { border: "none" },
                }}
              />
            </div> */}
            <div className="operation-cargo-dom-resp-weight-iyziteam-item">
              <div className="little-title">gw</div>
              <TextField
                size="medium"
                name="gw"
                disabled={disable}
                value={dim.gw}
                required
                onChange={(e) => handleInputChange(e, index)}
                sx={{
                  backgroundColor: "#e9ecef",
                  width: "70px",
                  height: "20px",
                  fontSize: "10px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0",
                  "& fieldset": { border: "none" },
                }}
              />
            </div>
            {/* <div className="operation-cargo-dom-resp-weight-iyziteam-item">
              <div className="little-title">
                <b style={{ fontSize: "inherit" }}>Brüt Ağırlık :</b>&nbsp;
              </div>
              <div
                style={{
                  backgroundColor: "#e9ecef",
                  width: "70px",
                  height: "20px",
                  fontSize: "10px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0",
                }}
              >
                {Number(dim.gw * dim.qty).toFixed(2)} KG
              </div>
            </div>
            <div className="operation-cargo-dom-resp-weight-iyziteam-item">
              <div className="little-title">Hacim / Desi</div>
              <div
                style={{
                  backgroundColor: "#e9ecef",
                  width: "70px",
                  height: "20px",
                  fontSize: "10px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0",
                }}
              >
                {Number(
                  ((dim.width * dim.length * dim.height) / 5000) * dim.qty
                ).toFixed(2)}
                KG
              </div>
            </div> */}
          </form>
        ))}
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "#6a707e",
          gap: "5px",
        }}
        className="operation-cargo-dom-resp-wight-shipment-bottom-side"
      >
        <div style={{ fontSize: "7px" }} className="little-title">
          Toplam Ücret Ağırlığı
        </div>
        <div
          style={{
            backgroundColor: "#e9ecef",
            width: "124px",
            height: "20px",
            fontSize: "10px",
            fontWeight: 700,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="operation-cargo-dom-resp-wight-shipment-item-bottom"
        >
          {totalCw} kg
        </div>
      </div> */}
    </div>
  );
}
