import "../../../style/operation/Operation.css";
import {
  Box,
  ThemeProvider,
  createTheme,
  Button,
  TextField,
  Alert,
  Tooltip,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { useCookies } from "react-cookie";
import axios from "../../../api/axios";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import NavBar from "../sidebar/NavBar";
import { ToastContainer } from "react-toastify";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import FlightTakeoffOutlinedIcon from "@mui/icons-material/FlightTakeoffOutlined";
import FlightLandOutlinedIcon from "@mui/icons-material/FlightLandOutlined";
import WrapperStyle from "../../../utils/WrapperStyle";
import WarehouseOutlinedIcon from "../../../img/images/la_warehouse.svg";
import countriesDetailed from "../../../json/countriesDetailed.json";
import questionIcon from "../../../img/images/ph_question-bold.svg";
import proformaLogo from "../../../img/images/mdi_map-outline.svg";
import OperationDimension from "./operationCargoEntranceWarehouse/OperationDimension";
import { CircleFlag } from "react-circle-flags";
import { useRecoilState, useRecoilValue } from "recoil";
import { ffdDataState } from "../../../atoms/operation";
import { drawerOpenState } from "../../../atoms/viewPadding";
import NotificationBar from "../sidebar/NotificationBar";
import Errors from "../../../utils/Errors";
// Barcode scan
// import useScanDetection from "use-scan-detection";
import { toast } from "react-toastify";
import { useRef } from "react";

import {
  myShipmentListLoadingState,
  myShipmentListState,
  myShipmentsListLimitState,
  shipmentListCurrentPageState,
  shipmentListOrderDirectionState,
  shipmentsListArrayLengthState,
  totalPagesState,
} from "../../../atoms/myShipments";
import OperationDimensionDomesticCheck from "./operationCargoEntranceWarehouse/OperationDimensionDomesticCheck";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const DomesticInfoChangesScreen = () => {
  DynamicTitle("Depo Yük Girişi - Admin");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
  const [ioss, setIoss] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [currentStatusValue, setCurrentStatusValue] = useState("");

  // Shipment list

  const [myShipmentListLoading, setMyShipmentListLoading] = useRecoilState(
    myShipmentListLoadingState
  );
  const shipmentOrderDirectionPage = useRecoilValue(
    shipmentListOrderDirectionState
  );
  const [myShipmentsListLimit, setMyShipmentsListLimit] = useRecoilState(
    myShipmentsListLimitState
  );
  const [shipmentListCurrentPage, setShipmentListCurrentPage] = useRecoilState(
    shipmentListCurrentPageState
  );
  const [myShipmentList, setMyShipmentList] = useState([]);
  const [totalPages, setTotalPages] = useRecoilState(totalPagesState);
  const [, setShipmentsListArrayLength] = useRecoilState(
    shipmentsListArrayLengthState
  );
  const manuelScanRef = useRef(null);
  // Main panel

  const [domAwb, setDomAwb] = useState("");
  const [domSupplier, setDomSupplier] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open4, setOpen4] = useState(false);

  // Ölçü inputları

  const [loading, setLoading] = useState(false);

  const [ffdData, setFfdData] = useRecoilState(ffdDataState);
  const [drawerOpen, setDrawerOpen] = useRecoilState(drawerOpenState);

  const [barcodeScan, setBarcodeScan] = useState("");
  const [manuelScan, setManuelScan] = useState("");
  const [getTrackingFromGunText, setGetTrackingFromGunText] = useState("");
  const [checkSettings, setCheckSettings] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  // useScanDetection({
  //   onComplete: setBarcodeScan,
  //   minLength: 3,
  // });

  // console.log(barcodeScan);

  useEffect(() => {
    if (manuelScanRef.current) {
      manuelScanRef.current.focus();
    }
  }, []);

  const getBeevisionList = async () => {
    try {
      const response = await axios.get("beevision/list", {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      setCurrentStatus(response.data.data[0].mode_description);
      setCurrentStatusValue(response.data.data[0].current_mode);
    } catch (error) {
      Errors(error);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "2.16":
        return "green";
      case "1.02":
      case "0.05":
      case "0.06":
        return "red";
      case "2.03":
        return "orange";
      case "0.00":
        return "blue";
      case "1.00":
      case "1.01":
        return "orange";
      default:
        return "red";
    }
  };

  const shipmentStatus = myShipmentList[0]?.status;

  const getTrackingFromGun = useCallback(async () => {
    try {
      if (barcodeScan.trim() !== "") {
        const response = await axios.post(`beevision/new-tracking-from-gun`, {
          domestic_awb: manuelScan,
        });
        setGetTrackingFromGunText(response.message);
      } else {
        console.log("BarcodeScan is empty. No request made.");
      }
    } catch (error) {
      Errors(error);
    } finally {
      setMyShipmentListLoading(false);
    }
  }, [manuelScan]);

  const getShipment = async () => {
    if (manuelScan.trim() === "") {
      return;
    } else if (manuelScan.length >= 8) {
      setMyShipmentListLoading(true);
      setCheckSettings(true);

      try {
        const response = await axios.get(
          `parcel?order_direction=${shipmentOrderDirectionPage}&limit=100000000&page=${shipmentListCurrentPage}&unlimited=1&domestic_awb=${manuelScan}&order_by=updated_at&order_direction=DESC&detailed=1`
        );

        // const filteredData = response.data.data.filter(
        //   (item) => item.domestic_awb === manuelScan
        // );

        setMyShipmentList(response.data.data);
        console.log(myShipmentList)
      } catch (error) {
        Errors(error);
      } finally {
        setMyShipmentListLoading(false);
        setCheckSettings(false);
        setManuelScan("");
        getTrackingFromGun();
      }
    }
  };

  useEffect(() => {
    getBeevisionList();
    const intervalId = setInterval(() => {
      getBeevisionList();
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getShipment();
  }, [manuelScan]);

  useEffect(() => {
    setDrawerOpen(false);
  }, []);

  const handleManuelScanChange = (event) => {
    setManuelScan(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <NotificationBar />
      <section
        className="operation"
        style={{
          // paddingLeft: drawerOpen ? 0 : "64px",
          transition: "all 150ms linear",
        }}
      >
        <NavBar />
        <ToastContainer />
        {loading && (
          <Box
            sx={{
              height: "500px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="loading-logo" src={miniLogo} alt="" />
          </Box>
        )}
        {!loading && (
          <div
            style={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                height: "90px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "10px",
                backgroundColor: "#ffff",
                marginBottom: "10px",
                paddingLeft: drawerOpen ? "285px" : "20px",
                transition: "all 150ms linear",
              }}
            >
              <img
                style={{ width: "40px", height: "40px" }}
                src={WarehouseOutlinedIcon}
                alt="Icon"
              />
              <h6
                style={{
                  color: "var(--priceText)",
                }}
              >
                Domestik Bilgi Düzenleme Ekranı
              </h6>
              <div
                style={{
                  width: "193px",
                  Height: "32px",
                  border: " 1px solid #4285F4",
                  padding: "5px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: " center",
                  alignItems: "center",
                  color: " rgb(66, 133, 244)",
                  marginLeft: "10px",
                  gap: "5px",
                  cursor: "pointer",
                }}
              >
                <img src={questionIcon} alt="question icon" />
                Bu Sayfa Nasıl Kullanılır
              </div>
            </div>

            <Box className="operation-wrapper" style={WrapperStyle()}>
              <Box className="operation-container">
                <Box sx={{
                  display: "flex",
                  gap: "250px"
                }}>
                  <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px"
                  }}>
                    <img
                      style={{ width: "150px", margin: " 10px auto 0" }}
                      src="/images/save.jpeg"
                      alt="IyziShip Icon"
                    />
                    <span>
                      KAYDET
                    </span>
                  </Box>
                  <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px"
                  }}>
                    <img
                      style={{ width: "150px", margin: " 10px auto 0" }}
                      src="/images/edit.jpeg"
                      alt="IyziShip Icon"
                    />
                    <span>
                      DÜZENLE
                    </span>
                  </Box>
                  <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px"
                  }}>
                    <img
                      style={{ width: "150px", margin: " 10px auto 0" }}
                      src="/images/new.jpeg"
                      alt="IyziShip Icon"
                    />
                    <span>
                      YENİ
                    </span>
                  </Box>
                </Box>
                {/* <div
                  className="operation-freight-front-desk"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "100%",
                  }}
                >
                </div> */}
                <div
                  className="operation-freight-front-desk"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "100%",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <label>Domestik Awb</label>
                    <TextField
                      value={manuelScan}
                      size="small"
                      label=""
                      sx={{ width: "100%" }}
                      onChange={handleManuelScanChange}
                      inputRef={manuelScanRef} // Referansı buraya ekliyoruz
                    />
                  </Box>
                </div>
                <div
                  className="operation-freight-front-desk"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "100%",
                  }}
                >
                  <Box>
                    <label>Domestik AWB No</label>
                    <TextField
                      value={myShipmentList[0]?.domestic_awb}
                      autoComplete="on"
                      size="small"
                      label=""
                      sx={{ width: "100%" }}
                      disabled
                    />
                  </Box>
                  <Box>
                    <label>Domestik Tedarikçi</label>
                    <TextField
                      value={myShipmentList[0]?.domestic_supplier}
                      autoComplete="on"
                      size="small"
                      label=""
                      sx={{ width: "100%" }}
                      disabled
                    />
                  </Box>
                  <Box>
                    <label>Parcel No</label>
                    <TextField
                      value={myShipmentList[0]?.parcel_ref_no}
                      autoComplete="on"
                      size="small"
                      label=""
                      sx={{ width: "100%" }}
                      disabled
                    />
                  </Box>
                </div>

              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  backgroundColor: "var(--lighterBg)",
                  borderRadius: "10px",
                  gap: "10px",
                  padding: "15px 50px",
                }}
              >
                <Box>
                  <div className="operation-cargo-dom-resp-weight">
                    <div className="operation-cargo-dom-resp-weight-iyziteam">
                      <div className="title">
                        Domestik Ölçüler
                      </div>
                      <OperationDimensionDomesticCheck
                        item={
                          myShipmentList
                        }
                        items={
                          myShipmentList[0]?.get_parcel_domestic_dimensions
                        }
                        i={myShipmentList[0]?.get_parcel_domestic_dimensions?.length}
                        id={myShipmentList[0]?.id}
                        isBeevision={true}
                        manuelScan={manuelScan}
                        setManuelScan={setManuelScan}
                      />
                    </div>
                  </div>
                </Box>
              </Box>
            </Box>
          </div>
        )}
        <Dialog open={checkSettings} onClose={checkSettings}>
          <DialogContent>
            <DialogTitle>
              <DialogContentText id="alert-dialog-description">
                Barkod okunuyor lütfen bekleyiniz...
              </DialogContentText>
            </DialogTitle>
          </DialogContent>
        </Dialog>
      </section>
    </ThemeProvider>
  );
};

export default DomesticInfoChangesScreen;
